import $ from 'jquery';
import TweenLite from 'gsap/TweenLite';
import { Expo } from 'gsap/EasePack';

/**
 * Filter projects
 */
function addEvents() {
  const $projects = $('.project-list__item');
  const $filters = $('.project__filter');

  $('.project__filter').on('click', (event) => {
    const { tid } = event.target.dataset;
    $filters.removeClass('is-active');

    if (tid !== '-1') {
      $projects.hide();
      $(`.js-project-${tid}`).show();
      $(event.target).addClass('is-active');
    } else {
      $projects.show();
      $filters.first().addClass('is-active');
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  // Scroll Icon
  if ($('.scroll__button').length > 0) {
    $('.scroll__button').click(function click() {
      const h = $('#a-propos').outerHeight();
      const m = window.innerHeight;
      TweenLite.to(document.querySelector('.scroll-container'), 0.3, {
        scrollTo: {
          y: h / 2,
        },
        ease: Expo.easeInOut,
      });
      TweenLite.to(window, 0.3, {
        scrollTo: {
          y: m,
        },
        ease: Expo.easeInOut,
      });
    });
  }

  $('.menu__button').on('click', () => {
    $('.menu').toggleClass('is-open');
  });

  $('.menu__link').on('click', () => {
    $('.menu').toggleClass('is-open');
    if (
      window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
      window.location.hostname === this.hostname
    ) {
      const $target = $(this.hash);
      if ($target.length) {
        TweenLite.to(window, 0.8, {
          scrollTo: {
            y: $target.offset().top - 20,
          },
          ease: Expo.easeInOut,
        });
        return false;
      }
    }
    return true;
  });

  if ($('.project-list__item')) {
    addEvents();
  }
});

// framework.init();

// Add custom resize event
// let timer;
// window.addEventListener('resize', () => {
//   clearTimeout(timer);
//   timer = setTimeout(() => {
//     framework.resize(window.innerWidth, window.innerHeight);
//   }, 200);
// });


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
